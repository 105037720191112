
export default {
	path: "opciones",
	name: "settings-form-options",
	meta: {
		label: "Opciones",
		nav: true,
		subject: "settings",
		action: "read",
	},
	component: () => import("@/page/private/Settings/VOptions")
};