<template>
  <div class="relative flex min-h-screen bg-zinc-100">
    <aside class="bg-white shadow flex flex-col">
      <Panel :navs="navs"></Panel>
    </aside>
    <main class="p-3 md:p-6 grid grid-cols-1 gap-4 w-1 flex-grow auto-rows-min">
      <TopBar class="sticky top-0 z-40">
        <div class="flex gap-4">
          <div class="flex flex-grow gap-4 items-center">
            <figure v-if="bussiness?.photo?.url" class="-my-2">
              <img
                class="h-10 w-auto block"
                :src="bussiness?.photo?.url"
                alt=""
              />
            </figure>
            <span
              v-if="bussiness?.name"
              :title="bussiness?.name"
              class="uppercase font-bold overflow-ellipsis overflow-hidden w-1 flex-grow whitespace-nowrap"
            >
              {{ bussiness?.name }}
            </span>
          </div>
          <div class="items-center ml-auto hidden xl:flex">
            <router-link
              :to="{ name: 'profile' }"
              class="flex gap-3 items-center"
            >
              <span class="block w-6 rounded overflow-hidden bg-zinc-200">
                <i class="block aspect-square p-1 relative">
                  <img
                    v-if="user?.photo"
                    class="absolute inset-0 w-full h-full"
                    :src="user.photo?.url"
                    alt="Photo"
                  />
                  <fa-icon
                    v-else
                    class="absolute inset-1 m-auto opacity-30"
                    :icon="faUser"
                  />
                </i>
              </span>
              <span class="font-medium">
                {{ user?.name }}
              </span>
            </router-link>
            <div class="text-xs border-l pl-2 ml-2 py-1">
              <button @click="onSignout" class="opacity-75">
                Cerrar Sesión
              </button>
            </div>
          </div>
          <div class="xl:hidden flex ml-auto text-gray-400 gap-4">
            <span class="aspect-square w-3.5 md:w-5">
              <fa-icon
                @click="onToggleMenu"
                class="w-full h-full cursor-pointer block"
                :icon="menuMobile ? faClose : faBars"
              />
            </span>
            <span class="border-l h-full"></span>
            <span class="aspect-square w-3.5 md:w-5">
              <fa-icon
                @click="onSignout"
                class="w-full h-full cursor-pointer block"
                :icon="faPowerOff"
              />
            </span>
          </div>
        </div>
      </TopBar>
      <transition name="slide-up" mode="out-in">
        <div :key="titles.title || $route.meta.title">
          <h1 class="text-xl md:text-2xl font-semibold">
            {{ titles.title || $route.meta.title }}
          </h1>
          <p class="opacity-80" v-if="titles.subtitle">{{ titles.subtitle }}</p>
        </div>
      </transition>
      <!-- <div>
				<template v-for="route in $route.matched.slice(1)">
					
				</template>
			</div> -->
      <!-- <router-view></router-view> -->
      <router-view v-slot="{ Component }">
        <transition name="slide-down" mode="out-in">
          <component :is="Component" v-if="show" />
        </transition>
      </router-view>
    </main>
  </div>
</template>
<script setup>
import {
  faUser,
  faBars,
  faClose,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { ref, computed, onMounted, watch, provide, reactive } from "vue";

import axios from "@/assets/js/axios";
import { updateAbility } from "@/assets/js/abilitys";
import { useAbility } from "@casl/vue";
const ability = useAbility();

import { bussiness } from "@/mixins/MixinSettings";
import Jwt from "@/assets/js/jwt";

import Panel from "@/views/layout/VPanel";
import TopBar from "@/views/layout/TopBar";

import { useStore } from "vuex";
const store = useStore();

import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
const route = useRoute();
const router = useRouter();

const show = ref(false);
const navs = computed(() => {
  return router.options.routes[1].children;
});

const user = computed(() => {
  return store.state["Users"].user;
});

const authRoute = () => {
  const { action, subject } = route.meta;
  const auth = ability.rules.find(
    (item) => item.action === action && subject.includes(item.subject)
  );

  if (!auth && route.name != "not-found") {
    router.push({ name: "not-authorized" });
  }
};
const onSignout = async () => {
  const { status, data } = await axios({
    method: "post",
    url: "auth/signout",
  }).catch((e) => e.response);

  if (status === 200) {
    router.push({ name: "signin" });
    Jwt.removeSignin();
    ability.update([]);
  } else {
    console.error(data);
  }
};
const onSignIn = async () => {
  if (localStorage.signin) {
    const { status, data } = await axios({
      method: "post",
      url: "auth/signin",
    }).catch((e) => e.response);

    const { row, modules } = data;
    if (status === 200) {
      store.commit("SET_STATE", {
        data: row,
        prop: "user",
        modules: "Users",
      });

      ability.update(updateAbility(modules, row.rol));
      show.value = true;
    }
  }
};

const titles = reactive({
  title: null,
  subtitle: null,
});

provide("title", {
  setTitle: (title, value) => {
    titles[title] = value;
  },
});

onBeforeRouteUpdate((to, from) => {
  if (to.name != from.name) {
    if (titles.title) titles.title = null;

    if (titles.subtitle) titles.subtitle = null;
  }
});

const menuMobile = computed(() => store.state.menuMobile);
const onToggleMenu = () => {
  store.commit("TOGGLE_MENU", !menuMobile.value);
};

watch(
  () => route.path,
  () => {
    authRoute();
    if (window.innerWidth < 1280) store.commit("TOGGLE_MENU", false);
  },
  { deep: true }
);

onMounted(async () => {
  await onSignIn();
  authRoute();
  window.onresize = () => {
    store.commit("TOGGLE_MENU", window.innerWidth >= 1280);
  };
});
</script>
