import { computed } from 'vue'
import store from "@/store"
import axios from "@/assets/js/axios"
export const bussiness = computed(() => (store.state["Settings"].bussiness));

export const onSubmitBussiness = async (config) => {
	const {status, data:res} = await axios(config).catch( e => e.response);
	const data = {};
	switch(status) {
		case 200:
			for(let {key, value} of res.rows)
          data[key] = value;
        
      store.commit("SET_STATE", {
        prop: "bussiness",
        modules: "Settings",
        data
      });
	}
	return {data, res, status};
}

export const mapColumns = (value) => {
	switch(value) {
		case '1/3':
			return "col-span-6 md:col-span-3 lg:col-span-2";
		case '1/2':
			return "col-span-6 lg:col-span-3";
		case '2/3':
			return "col-span-6 lg:col-span-4";
		default:
			return "col-span-6";
	}
}

export const mapColumnsInputs = (value, field) => {
	if(field == "description"){
		switch(value) {
			case '1/3':
			case '1/2':
				return "col-span-6 lg:col-span-6";
			case '2/3':
				return "col-span-6 lg:col-span-3 lg:row-span-2";
			default:
				return "col-span-6 lg:col-span-2 lg:row-span-2";
		}
	}else{
		switch(value) {
			case '1/3':
				return "col-span-6";
			case '1/2':
			case '2/3':
				return "col-span-6 md:col-span-3 lg:col-span-3";
			default:
				return "col-span-6 md:col-span-3 lg:col-span-2";
		}
	}
}
