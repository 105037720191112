export default {
	path: "secciones",
	name: "settings-form-sections",
	redirect: { name: "setting-sections" },
	meta: {
		label: "Características",
		nav: true,
		subject: "settings",
		action: "read",
	},
	component: () => import("@/views/layout/VRouterView"),
	children: [{
			path: "",
			name: "setting-sections",
			meta: {
				subject: "settings",
				action: "read",
			},
			component: () => import("@/page/private/Settings/Sections/VSections"),
		},
		{
			path: "crear",
			name: "settings-section-create",
			meta: {
				title: "Crear Sección",
				subject: "settings",
				action: "create",
			},
			component: () => import("@/page/private/Settings/Sections/VSectionDetail")
		},
		{
			path: "editar/:id",
			name: "settings-section-edit",
			meta: {
				title: "Editar Sección",
				subject: "settings",
				action: "update",
			},
			component: () => import("@/page/private/Settings/Sections/VSectionDetail")
		}
	]
};