import Users from "./Users"
import Products from "./Products"
import Rols from "./Rols"
import Customers from "./Customers"
import Settings from "./Settings"

export default {
	Users,
	Products,
	Rols,
	Customers,
	Settings
}