class JsonWebToken {
	constructor(){
		this.signin = this.getSignin();
	}
	
	get signin(){
		return this._signin;
	}

	set signin(value){
		this._signin = value;
	}

	setSignin(value, callback){
		localStorage.setItem("signin", JSON.stringify(value));
		this.signin = this.getSignin();

		if(callback)
			callback(this.signin);
	}

	getSignin(){
		return JSON.parse(localStorage.getItem("signin"));
	}

	removeSignin(){
		localStorage.removeItem("signin");
		this.signin = null;
	}
}

const Jwt = new JsonWebToken();

export default Jwt;