export default {
	namespaced: true,
	state: () => ({
		sections: [],
		profiles: [],
		documents: [],
		bussiness: {}
	}),
	mutations: {
		ORDER_STATE(state, {prop, newIndex, oldIndex, element}){
			state[prop].splice(oldIndex, 1);
			state[prop].splice(newIndex, 0, element);
			state[prop].forEach((item, index) => {
				item.position = index;
			});
		},
	}
}