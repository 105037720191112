import { defineAbility } from '@casl/ability';

export const action = {
	create: "create",
	read: "read",
	update: "update",
	delete: "delete",
	import: "import",
	export: "export",
}

export const subject = {
	profile: "profile",
	users: "users",
	products: "products",
	observations: "observations",
	customers: "customers",
	rols: "rols",
	settings: "settings",
	icons: "icons"
}

export const updateAbility = (modules, rol) => {
	let result = [];
	if(rol.ranking === "0"){
		for(let sub in subject){
			for(let act in action){
				result.push({ action: action[act], subject: subject[sub] });
			}
		}
	}else{
		modules.forEach(({permissions, ...Module}) => {
			permissions.forEach((Permission) => {
				result.push({ action: Permission.alias, subject: Module.alias })	
			})
		});
		result.push({ action: action.read, subject: subject.icons });
	}
	return result;
}

export default defineAbility(() => {});