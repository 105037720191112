<template>
  <ul ref="panelMenu" class="panel-menu leading-tight">
    <li v-if="$slots.title" class="panel-item pl-6">
      <span class="panel-title font-semibold block p-2 flex-grow flex-shrink-0">
        <slot name="title"></slot>
      </span>
    </li>
    <template v-for="item in items" :key="`item_menu_${$.uid}_${item.name}`">
      <li
        v-if="item.meta?.nav && onAbilitys(item.meta)"
        class="panel-item"
        :class="{
          hasChildrens:
            item?.children?.filter((child) => child.meta.nav)?.length > 0,
        }"
      >
        <router-link :to="{ ...item }" class="panel-link relative flex">
          <span
            class="panel-icon p-2 flex items-center bg-white text-gray-500"
            v-if="item.meta?.icon"
          >
            <i class="block aspect-square w-4 relative">
              <fa-icon
                class="absolute inset-0 w-full h-full"
                :icon="fas[item.meta?.icon]"
              />
            </i>
          </span>
          <span
            class="panel-link-text relative block p-2 pr-4 flex-grow flex-shrink-0"
          >
            {{ item.meta.title }}
          </span>
        </router-link>
        <ul
          class="panel-submenu overflow-hidden bg-zinc-50 shadow-inner pl-8"
          v-if="item?.children?.filter((child) => child.meta.nav)?.length"
        >
          <template
            v-for="subItem in item.children"
            :key="`item_submenu_${$.uid}_${subItem.name}`"
          >
            <li class="panel-item" v-if="subItem.meta?.nav">
              <router-link
                :to="{ ...subItem }"
                class="panel-link relative flex"
              >
                <span
                  class="panel-link-text relative block p-2 flex-grow flex-shrink-0"
                >
                  {{ subItem.meta.title ?? subItem.meta.label }}
                </span>
              </router-link>
            </li>
          </template>
        </ul>
      </li>
    </template>
  </ul>
</template>
<script setup>
import { fas } from "@fortawesome/free-solid-svg-icons";
import { defineProps, onUpdated, ref } from "vue";

import { useAbility } from "@casl/vue";
const { can } = useAbility();

const onAbilitys = (meta) => {
	if (Array.isArray(meta?.subject)) {
		const res = meta.subject.map((item) => can(meta.action, item));
    return res.includes(true);
  } else {
    return can(meta?.action, meta?.subject);
  }
};

defineProps({
  items: {
    type: Object,
    required: true,
  },
});

const panelMenu = ref(null);
onUpdated(() => {
  const subPanles = panelMenu.value.querySelectorAll(".panel-submenu");
  subPanles.forEach((item) => {
    item.style.maxHeight = item.scrollHeight + "px";
  });
});
</script>
<style lang="scss">
.panel-menu {
  $panel: ".panel";
  $link: "#{$panel}-link";
  $item: "#{$panel}-item";
  $submenu: "#{$panel}-submenu";
  #{$item}.hasChildrens {
    &:not(:hover) {
      :not(.router-link-active) + #{$submenu} {
        @apply #{"!max-h-0"};
      }
      // #{$submenu} {
      // 	@apply #{"!max-h-0"};
      // }
    }
    > #{$link} {
      &::after {
        content: "";
        @apply transition duration-500 w-full absolute bottom-0 left-0 -translate-x-full block border-b-2 border-red-500;
      }
      &.router-link-active,
      &:hover {
        &::after {
          @apply translate-x-0;
        }
      }
    }
  }
  #{$item}:not(.hasChildrens) {
    #{$link} {
      &-text {
        &::after {
          content: "";
          @apply transition duration-500 absolute top-1/2 right-0 -translate-y-1/2 translate-x-2 block border-[6px] border-transparent border-r-red-500 opacity-0;
        }
      }
      &.router-link-active,
      &:hover {
        #{$link}-text {
          &::after {
            @apply translate-x-0 opacity-100;
          }
        }
      }
    }
  }
  #{$item} {
    #{$link} {
      &.router-link-active,
      &:hover {
        @apply font-semibold;
        > .panel-icon {
          .toggle-bar & {
            @apply transition duration-500 text-red-500;
          }
        }
      }
    }
    #{$submenu} {
      @apply transition-all duration-500;
    }
    #{$submenu} {
      .nav-close.toggle-bar & {
        @apply #{"!max-h-0"};
      }
    }
  }
}
</style>
