export default {
	path: "ambientes",
	name: "settings-form-atmosphere",
	meta: {
		label: "Ambientes",
		nav: true,
		subject: "settings",
		action: "read",
	},
	component: () => import("@/page/private/Settings/VProfilesSections")
};