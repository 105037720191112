import axios from '@/assets/js/axios';
import { useToast } from 'vue-toastification'
const toast = useToast();

export default {
	namespaced: true,
	state: () => ({
		rols: [],
		modules: []
	}),
	mutations: {
		SET_STATE(state, { prop, data }) {
			state[prop] = data;
		},
		RESET_VALUES(state){
			state.modules.forEach(Module => {
				Module.value = [];
			})
		},
		VALUE_MODULES(state, data) {
			state.modules.forEach(Module => {
				if(data.length)
					data.forEach(value => {
						if(Module.id == value.id)
							Module.value = value.permissions.map(({id}) => ({id}));
					})
			})
		},
	},
	actions: {
		async getPermissionRols({commit}, {id}) {
			commit("RESET_VALUES");
			
			const {status, data} = await axios({
				url: `auth/rols/${id}`,
				method: "get"
			}).catch( e => e.response);


			switch (status) {
				case 200:
					commit("VALUE_MODULES", data);
					break;
				default:
					console.error(data);
			}
		},
		async updateRolPermission(ctx, {id, ...data}){
      const {status, ...res} = await axios({
				method: "post",
				url: `auth/rols/${id}`,
				data
			}).catch( e => e.response);
      
      const {message} = res.data;

      switch(status){
        case 200:

          toast.success(message);
          break;

        default:
          toast.error(message);
      }

      return status;
    },
	}
}