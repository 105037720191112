<template>
  <div id="MainApp">
    <router-view v-slot="{ Component }">
      <transition name="scale" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<script setup>
  import { onBeforeMount } from 'vue'
  import {onSubmitBussiness} from "@/mixins/MixinSettings"

  onBeforeMount(() => {
    onSubmitBussiness({
      url: "public/settings-options",
      method: "get"
    });
  })
</script>
<style lang="scss">
#nprogress .bar {
  @apply h-1 bg-red-500 #{!important};
}
.scale {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease-out;
  }
  &-enter-from,
  &-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
}
.fade-in {
  &-leave-active {
    position: absolute!important;
  }
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease-out;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
.fade {
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease-out;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
.slide {
  &-right {
    &-enter-active,
    &-leave-active {
      transition: all 0.4s ease-out;
    }
    &-enter-from,
    &-leave-to {
      @apply opacity-0 translate-x-2.5;
    }
  }
  &-left {
    &-enter-active,
    &-leave-active {
      transition: all 0.4s ease-out;
    }
    &-enter-from,
    &-leave-to {
      @apply opacity-0 -translate-x-2.5;
    }
  }
  &-down {
    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease-out;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(20px) scale(0.98);
    }
  }
  &-up {
    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease-out;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}

.swipe {
  &-left {
    &-enter-active,
    &-leave-active {
      transition: all 0.4s ease-out;
    }
    &-enter-from,
    &-leave-to {
      transform: translateX(100%);
    }
  }
  &-right {
    &-enter-active,
    &-leave-active {
      transition: all 0.4s ease-out;
    }
    &-enter-from,
    &-leave-to {
      transform: translateX(-100%);
    }
  }
}
</style>