export default {
  path: "roles-permisos",
  name: "rols-permissions",
  meta: {
    title: "Roles y Permisos",
    nav: true,
    subject: "rols",
    action: "read",
    icon: "faUnlock"
  },
  component: () => import("@/page/private/VRols")
};