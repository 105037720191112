import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Public from "@/views/Public";

import PrivateRoutes from "./private";

const routes = [
  {
    path: "/",
    redirect: { name: "signin" },
    component: Public,
    meta: {
      isPublic: true,
    },
    children: [
      {
        path: "signin",
        name: "signin",
        meta: {
          title: "Signin",
          nav: true,
          isLogout: true
        },
        component: () => import("@/page/public/VSignin"),
      },
      {
        path: "reset-password",
        name: "reset-password",
        meta: {
          title: "Recuperar contraseña",
          nav: true,
          isLogout: true
        },
        component: () => import("@/page/public/ResetPassword"),
      },
      {
        path: "reset-password/:id/:token",
        name: "reset-password-token",
        meta: {
          title: "Actualizar contraseña",
          nav: true,
          isLogout: true
        },
        component: () => import("@/page/public/ResetPasswordToken"),
      },
    ],
  },
  PrivateRoutes,
  {
    path: "/inmueble/:id/:customer",
    name: "product-info",
    meta: {
      isPublic: true,
    },
    component: () => import("@/page/public/Products/VProductInfo"),
  },
  {
    path: "/observacion/:id/:obs/:customer",
    name: "observation-info",
    meta: {
      isPublic: true,
    },
    component: () => import("@/page/public/Products/VObservationSignature"),
  },
  {
    path: "/no-autorizado",
    name: "not-authorized",
    component: () => import("@/page/NotAuthorized"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    meta: {
      isPublic: true,
    },
    component: () => import("@/page/NotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  
  if (to.name) {
    NProgress.start();
  }

  if (!to?.matched[0]?.meta?.isPublic && !localStorage.signin) {
    return next({ name: "signin" });
  }

  if (localStorage.signin && to.meta.isLogout) {
    return next({ name: "application" });
  }

  return next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
