import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/main.scss"
import "@/assets/js/rules";

import { register } from 'swiper/element';
register();

const app = createApp(App).use(store).use(router);

/* Icons */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
app.component("FaIcon", FontAwesomeIcon);

/* Abilitys */
import { abilitiesPlugin } from '@casl/vue';
import defineAbility from "@/assets/js/abilitys"
app.use(abilitiesPlugin, defineAbility, {
  useGlobalProperties: true
});

import Toast, {useToast} from "vue-toastification";
import "vue-toastification/dist/index.css";

app.use(Toast);
app.config.globalProperties.$toast = useToast();

/* Axios */
import axios from "@/assets/js/axios"
app.config.globalProperties.$axios = axios;


app.mount('#app');