export default {
  path: "clientes",
  name: "customers",
  meta: {
    title: "Clientes",
    nav: true,
    subject: "customers",
    action: "read",
    icon: "faUsers"
  },
  component: () => import("@/page/private/Customers/VCustomers")
};