import { defineRule } from 'vee-validate';
import { ext, confirmed } from '@vee-validate/rules';

defineRule("required", (value) => {
	if(["", null, undefined].includes(value))
		return "Campo obligatorio";

	return true;
});

defineRule('email', value => {
  if(!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/.test(value))
    return 'Formato de Email invalido';
  
  return true;
});

defineRule('numeric', value => {
  if(!Number.isInteger(value))
    return 'Ingresar solo valores numericos';
  
  return true;
});

defineRule('minLength', (value, [limit]) => {
  if (value.length < limit) {
    return `Debe selecionar al menos ${limit} elemento(s)`;
  }
  return true;
});

defineRule("ext", ext);
defineRule("confirmed", confirmed);