import axios from "axios"
import Jwt from "../jwt"
import router from "@/router"
import { useToast } from 'vue-toastification'
const toast = useToast();

const {VUE_APP_HOST, VUE_APP_HTTP} = process.env;
const config = {
	baseURL: `${VUE_APP_HTTP}://${VUE_APP_HOST}/`
}

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(config => {
	const login = Jwt.getSignin();
	if(login?.tokens)
		config.headers['authorization'] = `Bearer ${login.tokens?.token}`;

	return config;
}, error => Promise.reject(error));

axiosInstance.interceptors.response.use(res => res, async (error) => {
	const login = Jwt.getSignin();
	if(login && error.response.status == 401){
			Jwt.removeSignin();
			router.push({name: "signin"});
			toast.info("Sesion Caducada")
	}
	return Promise.reject(error);
})

export default axiosInstance;