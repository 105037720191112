<template>
	<header>
		<div class="shadow bg-white px-5 py-3 rounded">
			<slot>
				
			</slot>
		</div>
	</header>
</template>
<script>
export default {

	name: 'VTopBar',

	data() {
		return {

		}
	}
}
</script>
<style lang="css" scoped>
</style>