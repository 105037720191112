<template>
	<nav @mouseover="onMouseOver" @mouseout="onMouseOut" class="text-[15px] panel-nav fixed inset-y-0 left-0 xl:sticky xl:top-0 z-50 transition-all duration-500 w-auto" :class="[toggleNav ? 'xl:w-48' : 'toggle-bar xl:w-8', {'nav-close': !stateNav, 'pointer-events-none': !menuMobile}]">
		<div class="z-10 overflow-hidden flex flex-col divide-y divide-gray-300 w-full xl:hover:w-48 transition-all duration-500 bg-white h-screen" :class="[toggleNav ? '' : 'xl:absolute top-0 left-0 shadow', {'-translate-x-full': !menuMobile}]">
			<VMenuPanel :items="navs.filter(item => !item.meta.settings)"></VMenuPanel>
			<template v-if="settings && $can('read', 'settings')">
				<VMenuPanel :items="navs.filter(item => item.meta.settings)">
					<template #title>
						Ajustes
					</template>
				</VMenuPanel>
			</template>
			<div @click="onToggleNav" class="hidden xl:flex panel-link leading-tight cursor-pointer">
				<span class="panel-icon p-2 flex items-center bg-white">
					<i class="block aspect-square w-4 relative opacity-60">
						<fa-icon class="absolute inset-0 w-full h-full" :icon="toggleNav ? faAngleDoubleLeft : faAngleDoubleRight" />
					</i>
				</span>
				<span class="block p-2 flex-grow flex-shrink-0">
					{{toggleNav ? "Cerrar" : "Abrir"}}
				</span>
			</div>
		</div>
		<span @click="onCloseMenu" class="-z-10 fixed inset-0 bg-black/[0.75] xl:hidden cursor-pointer transition duration-500" :class="{'opacity-0 pointer-events-none': !menuMobile}"></span>
	</nav>
</template>
<script setup>
import { faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons"
import { defineProps, onMounted, ref, computed } from "vue"

import VMenuPanel from "@/views/layout/panel/VMenuPanel"

import {useStore} from "vuex"
const store = useStore();

const props = defineProps({
	navs: {
		type: Array,
		default: () => ([])
	}
});

const settings = props.navs.find(item => item.meta.settings)

const panelToggle = JSON.parse(localStorage.getItem("panelToggle"));
const toggleNav = ref( panelToggle ?? true );
const onToggleNav = () => {
	toggleNav.value = !toggleNav.value;
	localStorage.setItem("panelToggle", toggleNav.value);
}

const stateNav = ref(false);
const onMouseOver = () => {
	stateNav.value = true;
}
const onMouseOut = () => {
	stateNav.value = false;
}

const menuMobile = computed(() => store.state.menuMobile);
const onCloseMenu = () => {
	store.commit("TOGGLE_MENU", false);
}

onMounted(() => {
	store.commit("TOGGLE_MENU", window.innerWidth >= 1280);
	window.onresize = () => {
		store.commit("TOGGLE_MENU", window.innerWidth >= 1280);
	}
});

</script>