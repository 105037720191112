export const ProductsDataRoutes = {
  path: "inmuebles",
  name: "parent-products",
  redirect: {
    name: "products-data",
    query: {
      leased_state: "available",
    },
  },
  meta: {
    title: "Inmuebles",
    nav: true,
    subject: "products",
    action: "read",
    icon: "faBuilding",
  },
  component: () => import("@/views/layout/VRouterView"),
  children: [
    {
      path: "",
      name: "products-data",
      query: {
        leased_state: "available",
      },
      meta: {
        title: "Inmuebles",
        subject: "products",
        action: "read",
      },
      component: () => import("@/page/private/Products/VProductsData"),
    },
    {
      path: "crear",
      name: "product-data-create",
      meta: {
        title: "Crear Inmueble",
        subject: "products",
        action: "create",
      },
      component: () => import("@/page/private/Products/VProductDataDetail"),
    },
    {
      path: "editar/:id",
      name: "product-data-edit",
      meta: {
        title: "Editar Inmueble",
        subject: "products",
        action: "update",
      },
      component: () => import("@/page/private/Products/VProductDataDetail"),
    },
  ],
};

export const ProductsRoutes = {
  path: "inventario",
  name: "parent-inventory",
  redirect: { name: "inventory" },
  meta: {
    title: "Inventario",
    nav: true,
    subject: ["products", "observations"],
    action: "read",
    icon: 'faFileClipboard',
  },
  component: () => import("@/views/layout/VRouterView"),
  children: [
    {
      path: "",
      name: "inventory",
      meta: {
        title: "Inventario",
        subject: ["products", "observations"],
        action: "read",
      },
      component: () => import("@/page/private/Products/VProducts"),
    },
    {
      path: "crear",
      name: "inventory-create",
      meta: {
        title: "Crear Inventario",
        subject: "products",
        action: "create",
      },
      component: () => import("@/page/private/Products/VProductDetail"),
    },
    {
      path: "editar/:id",
      name: "inventory-edit",
      meta: {
        title: "Editar Inventario",
        subject: "products",
        action: "update",
      },
      component: () => import("@/page/private/Products/VProductDetail"),
    },
    {
      path: "observaciones/:id",
      name: "inventory-obervations",
      meta: {
        title: "Observaciones",
        subject: "observations",
        action: "read",
      },
      component: () => import("@/page/private/Products/VProductsObservation"),
    },
  ],
};
