<template>
	<div class="min-h-screen overflow-hidden">
		<router-view v-slot="{ Component }">
      <transition name="slide-down" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
	</div>
</template>
<script>
export default {
	name: 'VPublic',
}
</script>
<style lang="css" scoped>
</style>