export default {
  path: "asesores",
  name: "users",
  meta: {
    title: "Asesores",
    nav: true,
    subject: "users",
    action: "read",
    icon: "faUserTie"
  },
  component: () => import("@/page/private/VUsers")
};