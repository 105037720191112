import Private from "@/views/VPrivate";
import UsersRoutes from "./users";
import { ProductsRoutes, ProductsDataRoutes } from "./products";
import CustomersRoutes from "./customers";
import RolsRoutes from "./rols";
import SettingsRoutes from "./settings";
export default {
  path: "/application",
  name: "application",
  redirect: { name: "profile" },
  component: Private,
  children: [
    {
      path: "perfil",
      name: "profile",
      meta: {
        title: "Perfil",
        nav: true,
        subject: "profile",
        action: "read",
        icon: "faUserEdit",
      },
      component: () => import("@/page/private/VProfile"),
    },
    {
      path: "perfil/:id",
      name: "profile-user",
      meta: {
        title: "Perfil",
        subject: "profile",
        action: "read",
        icon: "faUser",
      },
      component: () => import("@/page/private/VProfile"),
    },
    UsersRoutes,
    ProductsDataRoutes,
    ProductsRoutes,
    CustomersRoutes,
    RolsRoutes,
    SettingsRoutes,
    {
      path: "empresa",
      name: "settings-bussiness",
      meta: {
        title: "Empresa",
        nav: true,
        settings: true,
        subject: "settings",
        action: "update",
        icon: "faGears",
      },
      component: () => import("@/page/private/Settings/VBussiness"),
    },
    {
      path: "icons",
      name: "icons",
      meta: {
        title: "Icons",
        // nav:true,
        subject: "icons",
        action: "read",
        icon: "faShapes",
      },
      redirect: { name: "icons-solid" },
      component: () => import("@/views/layout/VRouterView"),
      children: [
        {
          path: "solid",
          name: "icons-solid",
          meta: {
            title: "Icons Solid",
            subject: "icons",
            action: "read",
            nav: true,
          },
          component: () => import("@/page/private/Icons/Solid"),
        },
        {
          path: "regular",
          name: "icons-regular",
          meta: {
            title: "Icons Regular",
            subject: "icons",
            action: "read",
            nav: true,
          },
          component: () => import("@/page/private/Icons/Regular"),
        },
        {
          path: "brands",
          name: "icons-brands",
          meta: {
            title: "Icons Brands",
            subject: "icons",
            action: "read",
            nav: true,
          },
          component: () => import("@/page/private/Icons/Brands"),
        },
      ],
    },
  ],
};
